import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import Loadable from '@loadable/component';

import './styles.scss';

const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));

const SwitchChecklist = () => {
	const switchList = [
		{
			image: 'strength.png',
			alt: 'Strength',
			description: 'Has there been a shift in his muscle strength or function?'
		},
		{
			image: 'weight.png',
			alt: 'Weight',
			description: 'Do the gains feel excessive?'
		},
		{
			image: 'independence.png',
			alt: 'Independence',
			description: 'Has his disease progressed?'
		},
		{
			image: 'time.png',
			alt: 'Time',
			description: 'Are activities taking longer than before (eg, walking, standing, etc.)?'
		},
		{
			image: 'communication.png',
			alt: 'Communication',
			description: 'Has his emotional state shifted?'
		},
		{
			image: 'health-status.png',
			alt: 'Health status',
			description: 'Is he maintaining his quality of life?'
		},
	];

	return (
		<Layout>
			<Seo title='Switch Considerations | EMFLAZA® (deflazacort)' description='The corticosteroid he starts on may not be the best for him to stay on. Get tips for a safe and successful switch. View full Prescribing Information & Important Safety Information.' />
			<div className='page--switch-checklist'>
				<Hero
					heroImage='hero-switch.png'
					heroImageMobile='hero-switch-mobile.png'
					breadcrumbs={['Getting Started', 'Switch Considerations']}
					title='SWITCH Considerations'
					addedClass='switch-breadcrumbs'
				/>

				<section>
					<div className='container'>
						<p className='text-xl font-black uppercase font-gotham max-w-[238px] md:max-w-[462px] md:text-4xl'>The corticosteroid he starts on may not be the best for him to stay on<sup className='superscript'>7,13</sup></p>
						<h2 className='mt-8 mb-6 text-xl font-bold text-center uppercase text-emflaza-blue-400 md:text-left md:text-2xl md:my-12'>Helpful considerations from the start of treatment<sup>4,14,15</sup></h2>
					</div>
					<div className='px-3 md:container'>
						<div className='xl:mr-isi xl:pr-20'>
							{
								switchList.map(item => (
									<div className={`flex items-center justify-between switch__${item.alt.toLowerCase().split(" ").join('-')}`}>
										<div className='mr-3 switch__image md:mr-8'>
											<Image imageName={item.image} alt={item.alt} />
										</div>
										<div className='flex-1 switch_container'>
											<p className='text-xs font-gotham md:text-xl'>{item.description}</p>
										</div>
									</div>
								))
							}
							</div>
					</div>
					<div className='container mt-9 md:mt-24'>
						<p className='text-xl font-bold md:text-2xl font-gotham'>S.W.I.T.C.H. for the here and now</p>
					</div>
				</section>

				<section className='relative'>
					<div className='whizzbang-line'>
						<WhizzbangLine imageName='whizzbang-line-1.png' />
					</div>
					<div className='container flex flex-wrap mt-20 mb-8 space-y-5 md:space-y-0 md:space-x-5 pt-9 md:mt-32 md:pt-12 xl:pr-48'>
						<Cta ctaUrl='/efficacy/muscle-function' iconUrl={'cta-emflaza-whizzbang.png'} ctaText='SEE WHY EMFLAZA IS DIFFERENT' addedClass='cta flex-1'/>
						<Cta ctaUrl='/getting-started/prescription-start-form' iconUrl={'cta-prescription.png'} ctaText='PRESCRIPTION START FORM' addedClass='cta flex-1'/>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default SwitchChecklist;
